/* eslint-disable react/prop-types */
// LIBRARIES
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Switch, Typography } from 'antd';
import { ceil, round } from 'lodash';
import { useNavigate } from 'react-router';

// COMPONENTS
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import BlockCSVExportModal from '../Blocks/BlockCSVExportModal';
import BlockTitle from '../Assets/shared/BlockTitle';
import ParetoList from './ParetoList';
import ParetoWaterfall from './ParetoWaterfall';
import TrackedIssueEditor from '../../components/Modal/TrackedIssueEditor';
import { SPAWrapper } from '../../components/SPAWrapper';
import { RolePermission } from '../../components/Permission';

// HELPERS
import CONSTANTS from '../../Constants';
import { paretoSumCsv, paretoWeightedCsv} from './Pareto.csv_exporter';
import { flash } from '../../components/Flash';
import { oeePareto } from '../../utils/oee';
import { saveCsv } from '../../utils/dataExports';
import { getBlocksTreeData } from '../../utils/blocks';

// ACTIONS
import { createTrackedIssueRequest } from '../../../store/old/TrackedIssues/TrackedIssues.action';

import './ParetoAnalysis.scss';
import { ParetoContext } from '.';
import Counter from '../../../components/Counter/Counter';
import findMeanTimeToRepair from '../../../lib/lean_ops/findMeanTimeToRepair';
import findMeanTimeBetweenFailure from '../../../lib/lean_ops/findMeanTimeBetweenFailure';
import { MttrTooltipContent, MtbfTooltipContent } from './ParetoCounterTooltips'
import useTranslate from '../../../hooks/useTranslate';
import ParetoLabelsModal from './ParetoLabelsModal';
import { ParetoPageConstants as K } from '../../../store/old/UI/pareto/pareto.constants';
import { setParetoPageViewMode } from '../../../store/old/UI/pareto/pareto.action';
import { findNodes } from '../../utils/helpers';
import { api_logUserAction } from '../../../store/old/User/User.services';

const EXPORT_KEY = 'export';
const CLASSIC_EXPORT_WEIGHTED_KEY = 'classic_export_weighted';
const CLASSIC_EXPORT_SUM_KEY = 'classic_export_sum';

const oee1Fields = new Set(['ct', 'na', 'us', 'pd']);
const disabledFields = new Set(['na', 'uu']);
const loaIssues = new Set(['us', 'pd']);

const secondsToHours = (seconds) => (seconds / 3600).toFixed(2);

const ParetoAnalysisFC = (props) => {
    const { block } = props;
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(appState => appState.auth.user);

    const {
        oeeData,
        trackedIssues,
        controls: { oee2 },
        masks,
        issues,
        paretoStore: { viewMode }
    } = useContext(ParetoContext);

    const [showCSVExport, setShowCSVExport] = useState(false);
    const [showTrackedIssueEditor, setShowTrackedIssueEditor] = useState(false);
    const [trackedIssueParams, setTrackedIssueParams] = useState({});
    const [active, setActive] = useState(null);
    const [selection, setSelection] = useState(null);

    const clickToBlockView = () => {
        if (block.asset) {
            return navigate(`${CONSTANTS.URLS.ASSET}/${block.block_id}`);
        }

        return navigate(`${CONSTANTS.URLS.BLOCK}/${block.block_id}`);
    };

    const sumDatasource = useMemo(() => {
        if (block.asset || !block.children.length) return;
        
        const assets = getBlockAssets(block);
        const waterfall = getWaterfallChartDatasource(getCombinedAssetsOeeWaterfallData(assets));
        const labels = getCombinedAssetsEffectiveLabels(assets);
        const pareto = oeePareto(labels);

        return { waterfall, pareto, labels };
    }, [oeeData]);

    const weightedDatasource = useMemo(() => {
        const waterfall = getWaterfallChartDatasource(block.oee.waterfall);
        const labels = block.oee.effective_labels;
        const pareto = oeePareto(labels);

        return { waterfall, pareto, labels };
    }, [oeeData])
    
    const datasource = useMemo(() => {
        if (!block.asset && viewMode === K.VIEW_MODES.ASSETS) {
            return sumDatasource;
        }

        return weightedDatasource;
    }, [viewMode, weightedDatasource, sumDatasource])

    const paretoTitle = useMemo(() => {
        if (active) {
            const loss = active.loss.toUpperCase();
            const name = masks[active.key] ? masks[active.key].mask : active.name;
            return loss + ' - ' + name;
        }
        return 'All Labels';
    }, [active]);

    const clickCSVMenu = ({ key }) => { 
        if (key === EXPORT_KEY) {
            setShowCSVExport(true)
            return;
        }

        if (key === CLASSIC_EXPORT_WEIGHTED_KEY) {
            downloadWeightedCsv()
            return;
        }

        downloadSumCsv();
        return;
    };


    const isTracked = useCallback((issue) => {
        if (!block.asset) return false;

        return trackedIssues.find((item) => {
            return (
                issue &&
                item.issue_id === issue.issue_id &&
                item.assets.find((asset) => asset.asset_id === block.asset.asset_id)
            );
        });
    }, [trackedIssues]);

    const trackIssue = useCallback((d) => {
        const { asset } = block;
        if (!asset) return;

        const { asset_id } = asset;

        if (!issues[d.issue.issue_id]) {
            return flash({
                message: 'Cannot track a deleted issue',
                status: 'warning',
            });
        }

        if (d.isTracked) {
            return flash({
                message: 'This issue is being tracked',
                status: 'info',
            });
        }

        setShowTrackedIssueEditor(true);
        setTrackedIssueParams({
            assets: [{ asset_id }],
            issue_id: d.issue && d.issue.issue_id,
        });
    }, [issues]);

    const oeeParetoData = useMemo(() => {
        if (!datasource.waterfall.length) return [];

        const duration = oee2
            ? datasource.waterfall.find(({ key }) => key === 'pt').duration
            : datasource.waterfall.find(({ key }) => key === 'ct').duration;

        return getBlockLabelsParetoData({
            duration,
            active,
            oee2,
            isTracked,
            data: datasource.pareto,
            formatDataLabel: (!block.asset && viewMode === K.VIEW_MODES.ASSETS) ? 
                formatAssetsParetoListItemLabel : 
                formatBlockParetoListItemLabel
        });
    }, [datasource, block, active, masks, oee2, isTracked]);

    const breakdowns = useMemo(() => block.asset ? block.oee.effective_labels.filter(l => l.oee === 'bd') : [], [block.oee])
    const mtbf = useMemo(() => block.asset ? findMeanTimeBetweenFailure(block.oee.waterfall, breakdowns) : undefined, [block.oee, breakdowns])
    const mttr = useMemo(() => block.asset ? findMeanTimeToRepair(breakdowns) : undefined, [breakdowns])

    const downloadWeightedCsv = useCallback(async () => {
        if (!weightedDatasource.waterfall.length) return;

        let csvStr = paretoWeightedCsv(weightedDatasource.waterfall, weightedDatasource.pareto, block.asset ? { mtbf, mttr } : null, weightedDatasource.labels);
        saveCsv(csvStr, 'pareto_export.csv');

        await api_logUserAction(user.user_id, { 
            action: 'export',
            resource_name: 'pareto',
            description: null,
            metadata: null
        });
    }, [weightedDatasource, mtbf, mttr]);

    const downloadSumCsv = useCallback(async () => {
        if (!sumDatasource) return;

        let csvStr = paretoSumCsv(sumDatasource.waterfall, sumDatasource.pareto, sumDatasource.labels);
        saveCsv(csvStr, 'pareto_export.csv');

        await api_logUserAction(user.user_id, { 
            action: 'export',
            resource_name: 'pareto',
            description: null,
            metadata: null
        });
    }, [sumDatasource,]);

    const showViewMode = useMemo(() => !block.asset, [block])

    return (
        <SPAWrapper className="pareto-analysis d-flex flex-column">
            <BlockCSVExportModal
                visible={showCSVExport}
                onCancel={() => setShowCSVExport(false)}
                block={block}
            />
            <div className='d-flex'>
                <BlockTitle
                    id={block ? block.block_id : null}
                    getTreeData={(node) => getBlocksTreeData(node)}
                >
                    <div className="d-flex justify-content-between" style={{flexGrow: 1}}>
                        <div className="d-flex">
                            <AukTooltip.Help title={translate('timeseries')}>
                                <AukButton.Outlined
                                    className="auk-button--round mr-2"
                                    onClick={clickToBlockView}
                                    disabled={!block}
                                >
                                    <i className="fas fa-chart-bar" />
                                </AukButton.Outlined>
                            </AukTooltip.Help>
                            <RolePermission accessLevel="editor">
                                <AukTooltip.Help title={translate('export')}>
                                    <AukButton.Dropdown
                                        disabled={!block}
                                        className="auk-button--round mr-2"
                                        icon={<i className="fas fa-download" />}
                                        placement="bottomLeft"
                                        trigger={['click']}
                                        overlay={
                                            <Menu onClick={clickCSVMenu}>
                                                <Menu.Item key={EXPORT_KEY}>Export</Menu.Item>
                                                {
                                                    block.asset ? 
                                                        <Menu.Item key={CLASSIC_EXPORT_WEIGHTED_KEY}>Classic Export</Menu.Item> :
                                                        (
                                                            <>
                                                                <Menu.Item key={CLASSIC_EXPORT_WEIGHTED_KEY}>Classic Export (Weighted)</Menu.Item>
                                                                <Menu.Item key={CLASSIC_EXPORT_SUM_KEY}>Classic Export (Sum)</Menu.Item>
                                                            </>
                                                        )
                                                }
                                            </Menu>
                                        }
                                    />
                                </AukTooltip.Help>
                            </RolePermission>
                        </div>
                        {
                            showViewMode ? (                            
                                <div className="d-flex align-items-center">
                                    <Switch
                                        style={{width: 90}}
                                        onChange={() => { dispatch(setParetoPageViewMode(viewMode === K.VIEW_MODES.ASSETS ? K.VIEW_MODES.BLOCK :K.VIEW_MODES.ASSETS)) }}
                                        checkedChildren="Sum"
                                        unCheckedChildren="Weighted"
                                        checked={viewMode === K.VIEW_MODES.ASSETS}
                                    />
                                </div>
                            ) : null
                        }
                        </div>
                    <div></div>
                </BlockTitle>
                {
                    block.asset ?
                        <div className='d-flex'>
                            <Counter 
                                label={<Typography.Text strong style={{fontSize: 14}}>MTBF</Typography.Text>}
                                withTooltip
                                tooltipContent={<MtbfTooltipContent/>}
                                value={mtbf > 0 ? <ParetoCounterValue seconds={mtbf}/> : undefined}
                                placeholder="--"
                            />
                            <Counter
                                label={<Typography.Text strong style={{fontSize: 14}}>MTTR</Typography.Text>}
                                withTooltip
                                tooltipContent={<MttrTooltipContent/>}
                                value={mttr > 0 ? <ParetoCounterValue seconds={mttr}/> : undefined}
                                placeholder="--"
                            />        
                        </div>
                        : null
                }
            </div>
            <div className="d-flex h-100" style={{ overflow: 'hidden', marginTop: '0.5em' }}>
                <ParetoWaterfall
                    data={datasource.waterfall}
                    handleClick={(d) => d.issue_id && setActive(active && active.key === d.key ? null : d)}
                    accessors={{
                        active: d => active ? d.name === active.name : false,
                        opacity: d => oee2 ? (!oee1Fields.has(d.key) ? 1 : 0.25) : 1,
                        disabled: d => disabledFields.has(d.key),
                        formatDataLabel: d => (!d.asset && viewMode === K.VIEW_MODES.ASSETS) ? getAssetsParetoLabel(d, masks, translate)  : getBlockParetoLabel(d, masks, oee2, translate)
                    }}
                />
                <div className="w-50 px-2">
                    {oeeParetoData.length ? (
                        <ParetoList
                            title={paretoTitle}
                            data={oeeParetoData}
                            handleTrack={(d) => trackIssue(d)}
                            handleInspect={(d) => setSelection(d)}
                        />
                    ) : null}
                </div>
                <TrackedIssueEditor
                    show={showTrackedIssueEditor}
                    data={trackedIssueParams}
                    save={(d) =>
                        dispatch(
                            createTrackedIssueRequest(d, () => {
                                flash({ message: 'Created new tracked issue' });
                                setShowTrackedIssueEditor(false);
                            })
                        )
                    }
                    toggle={() => setShowTrackedIssueEditor(false)}
                />
            </div>
            <ParetoLabelsModal
                title={selection ? selection.issue.issue : ''}
                open={!!selection}
                data={selection ? datasource.labels.filter((l) => l.issue_id === +selection.issue.issue_id) : null}
                cancel={() => { setSelection(null) }}
            />
        </SPAWrapper>
    );
};

export default ParetoAnalysisFC;

const ParetoCounterValue = (props) => {
    const minutes = ceil(props.seconds / 60, 1);
    const hours = ceil(props.seconds / 3600, 1)
    return <div className="d-flex flex-column">
        <Typography.Text style={{fontSize: 12}}>{hours.toLocaleString()} hours</Typography.Text>
        <Typography.Text style={{fontSize: 12}}>({minutes.toLocaleString()} minutes)</Typography.Text>
    </div>
}

const getBlockLabelsParetoData = ({duration, active, oee2, data, isTracked, formatDataLabel}) => {
        const filtered = data.filter((d) => {
            const isOEE2 = oee2
                ? d.issue && d.issue.oee
                    ? !loaIssues.has(d.issue.oee)
                    : true
                : true;

            const isActive = active ? d.issue.oee === active.key : true;
            return isOEE2 && isActive;
        });

        const maxWidth = filtered.reduce(
            (acc, curr) =>
                curr.total_effective_duration > acc
                    ? curr.total_effective_duration
                    : acc,
            1
        );

        return filtered.map((d) => {
            const widthPercent = round(d.total_effective_duration / maxWidth, 2) * 100;

            return {
                ...d,
                issue: d.issue,
                color: d.issue.color,
                width: widthPercent,
                label: formatDataLabel(d, duration, oee2),
                isTracked: isTracked(d.issue),
            };
        });
}

const formatBlockParetoListItemLabel = (d, duration, oee2) => {
    const durationHours = (d.total_effective_duration / 3600).toFixed(2);
    const durationPercent = ((d.total_effective_duration / duration) *100).toFixed(1);

    return `${d.issue.name || 'DELETED'} (${durationHours}hrs, ${oee2 ? 'OEE2: ' : 'OEE1: '} ${durationPercent}%)`
}

const formatAssetsParetoListItemLabel = (d) => {
    const durationHours = (d.total_effective_duration / 3600).toFixed(2);

    return `${d.issue.name || 'DELETED'} (${durationHours}hrs)`
}

const getBlockAssets = (block) => {
    return findNodes(block, b => b.asset);
}

const getCombinedAssetsOeeWaterfallData = (assets) => {
    return assets.reduce((acc, curr, i) => {
        if (!acc) return curr.oee.waterfall.map(d => ({ ...d }));
        if (!curr.oee.waterfall || !curr.oee.waterfall.length) return acc;
        
        return acc.map((d, i) => {
            d.duration = d.duration + curr.oee.waterfall[i].duration
            return d
        });
    }, undefined);
}

const getCombinedAssetsEffectiveLabels = (assets) => {
    return assets.reduce((acc, curr) => {
        if (!acc) return [...curr.oee.effective_labels];
        return acc.concat(...curr.oee.effective_labels)
    }, undefined);
}

const getBlockParetoLabel = (d, masks, oee2, translate) => {
    const label_percent_duration = oee2
        ? d.oee2_percent_duration.toFixed(1)
        : d.percent_duration.toFixed(1);

    const key = d.type === 'time'
        ? translate(d.name)
        : masks[d.key]
            ? masks[d.key].abbreviation.toUpperCase()
            : d.key.toUpperCase();

    const durHours = secondsToHours(d.duration) + 'hrs';

    let percentDur = `${
        oee2 ? 'OEE2: ' : 'OEE1: '
    } ${label_percent_duration}%`;

    return `${key} (${durHours}, ${percentDur})`;
}

const getAssetsParetoLabel = (d, masks, translate) => {
    const key = d.type === 'time'
        ? translate(d.name)
        : masks[d.key]
            ? masks[d.key].abbreviation.toUpperCase()
            : d.key.toUpperCase();

    const durHours = secondsToHours(d.duration) + 'hrs';

    return `${key} (${durHours})`;
}

const getWaterfallChartDatasource = (data) => {
    const scheduledTime = data.find(({ key }) => key === 'pt');

    return data.map((d) => { 
        const oee2_percent_duration = d.duration / scheduledTime.duration * 100

        return {
            ...d,
            oee2_percent_duration
        };
    });
}
