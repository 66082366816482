import { ParetoPageConstants as K } from './pareto.constants'
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { errorFlash } from '../../../../legacy/components/Flash';

import { store } from '../../..';
import { findNodes } from '../../../../legacy/utils/helpers';
import { api_getAssetOEEData } from '../../Data/Data.services';

function* handleGetParetoAssetsData(action) {
    try {
        const { entity_id, block_id, query } = action.payload;
        const appState = store.getState();
        const { blocks: blocksResource } = appState.blocks;

        const asset_ids = findNodes(blocksResource[block_id], (b) => b.asset_id).map(b => b.asset_id);

        const promises = yield all([
            asset_ids.map(asset_id => call(api_getAssetOEEData(entity_id, asset_id, query)))
        ]);

        console.log(asset_ids, promises)
        // const { res_x, res_period } = resolution;

        // const oeeQuery = { date_range, res_x, res_period, sku_oee };
        // const labelsQuery = { date_range };
        // const eventsQuery = { date_range, conditions: JSON.stringify(conditions) };

        // const [_oee, _labels, _events] = yield all([
        //     call(api_getAssetOEEData, entity_id, asset_id, oeeQuery),
        //     call(api_getAssetLabels, entity_id, asset_id, labelsQuery),
        //     call(api_getAssetLabelEvents, entity_id, asset_id, eventsQuery)
        // ]);

        // const oee = OEEFactory(_oee);
        // const labels = _labels.map(l => new Label(...parseLabelArguments(l)));
        // const events = _events.map(v => ({ ...v, from: new Date(v.from), to: new Date(v.to)}));

        // yield put(setAssetEventsPageData({ oee, labels, events }))

        action.callback && action.callback(res);
    } catch (error) {
        errorFlash(error);
    }
}

export function* getParetoAssetsDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_PARETO_ASSETS_DATA,
        handleGetParetoAssetsData
    );
}
