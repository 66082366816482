/* eslint-disable react/prop-types */

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Layout } from 'antd';
import { initial, isEmpty, keys, values } from 'lodash';
import { useDispatch } from 'react-redux';

// HELPERS
import { findNodes } from '../../utils/helpers';
import { generateIssuesTreeData } from './IssuesTreeData';

// ACTIONS
import { updateBlocksIssues } from '../../../store/old/Blocks/Blocks.action';

// COMPONENTS
import BlockIssueSpreadsheet from './components/BlockIssueSpreadsheet';
import ConfirmationModal from '../../components/ConfirmationModal';
import AukTooltip from '../../components/AukTooltip';
import SearchableTree from '../../components/SearchableTree';
import { flash } from '../../components/Flash';
import { CheckboxLegend } from '../../components/Checkbox';

import { FilterOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

const { Sider } = Layout;

const generateBlocksTreeData = (node) => {
    const { label: title, block_id, asset } = node;

    const iconKey = asset ? 1 : 0;
    const icon = !asset ? (
        <span key={iconKey} style={{ fontSize: 10 }}>
            <i className="fas fa-th-large" />
        </span>
    ) : (
        <span key={iconKey} style={{ fontSize: 10 }}>
            <i className="fas fa-square" />
        </span>
    );

    if (node.children && !node.children.length) {
        return { label: title, title, value: block_id, key: block_id, icon };
    }

    const children = node.children.map((child) => generateBlocksTreeData(child));
    return {
        label: title,
        title,
        value: block_id,
        key: block_id,
        children,
        icon,
    };
};

const deriveExclusionsStateFromProps = (blocks) => {
    return blocks.reduce((acc, curr) => {
        const issues_excluded = keys(curr.issues_excluded).reduce((a, c) => {
            a[c] = {
                block_id: +curr.block_id,
                issue_id: +c,
                ib_id: curr.issues_excluded[c],
                checked: false,
                flag: false,
            };
            return a;
        }, {});

        acc[curr.block_id] = issues_excluded;
        return acc;
    }, {});
};

const IssuePermissionMatrix = (props) => {
    const {
        rootBlock,
        rootIssues,
        userCanEdit,
        blocks,
        issues,
        blocks_issues_exclusions,
    } = props;
    const dispatch = useDispatch();
    const { entity_id } = useSelector(currentEntitySelector)
    const [filterCollapsed, setFilterCollapsed] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [filteredBlock, setFilteredBlock] = useState();
    const [filteredIssue, setFilteredIssue] = useState();
    const [exclusions, setExclusions] = useState({});

    const initialExclusionsState = useMemo(
        () => deriveExclusionsStateFromProps(values(blocks)),
        [blocks_issues_exclusions]
    );

    useEffect(() => {
        setExclusions(initialExclusionsState)
    }, [initialExclusionsState])

    const blocksTreeData = useMemo(
        () => [generateBlocksTreeData(rootBlock)],
        [rootBlock]
    );

    const issuesTreeData = useMemo(
        () => rootIssues.map((node) => generateIssuesTreeData(node)),
        [rootIssues]
    );

    const matrixFormData = useMemo(() => {
        return keys(exclusions)
            .filter((block_id) => !isEmpty(exclusions[block_id]))
            .map((block_id) => values(exclusions[block_id]))
            .reduce((acc, curr) => acc.concat(curr), [])
            .filter(({ flag }) => flag)
            .map((item) => {
                const result = { ...item };
                delete result.flag;
                delete result.checked;
                return result;
            });
    }, [exclusions]);

    const handleCheckMultiple = (
        block,
        issue_id,
        currentChecked
    ) => {

        const leaves = findNodes(block, (b) => b.asset_id);

        const result = currentChecked
            ? uncheckMultiple(leaves, issue_id)
            : checkMultiple(leaves, issue_id);

        setExclusions({ ...exclusions, ...result });
    };

    const checkMultiple = (arrBlocks, issue_id) => {
        return arrBlocks.reduce((acc, curr) => {
            const { block_id } = curr;
            return { ...acc, [block_id]: check(curr, issue_id) };
        }, {});
    };

    const uncheckMultiple = (arrBlocks, issue_id) => {
        return arrBlocks.reduce((acc, curr) => {
            const { block_id } = curr;
            return { ...acc, [block_id]: uncheck(curr, issue_id) };
        }, {});
    };

    const check = (block, issue_id) => {
        const { block_id } = block;

        const cloned = { ...exclusions[block_id] };
        const ib_id = block.issues_excluded[issue_id];
        const checked = true;

        if (ib_id) {
            cloned[issue_id] = { ...cloned[issue_id], checked, flag: true };
        } else {
            delete cloned[issue_id];
        }

        return cloned;
    };

    const uncheck = (block, issue_id) => {
        const { block_id } = block;

        const cloned = { ...exclusions[block_id] };
        const ib_id = block.issues_excluded[issue_id];
        const checked = false;

        if (ib_id) {
            cloned[issue_id] = {
                ...cloned[issue_id],
                checked,
                ib_id,
                flag: !exclusions[block_id][issue_id],
            };
        } else {
            cloned[issue_id] = { issue_id, block_id, checked, flag: true };
        }

        return cloned;
    };

    const handleCheckSingle = (
        block,
        issue_id,
        currentChecked
    ) => {
        const { block_id } = block;

        const result = currentChecked
            ? uncheck(block, issue_id)
            : check(block, issue_id);

        setExclusions({
            ...exclusions,
            [block_id]: result,
        });
    };

    const handleClickCheckbox = (
        block_id,
        issue_id,
        currentChecked,
        hasPermission
    ) => {
        if (!userCanEdit) return;
        if (!hasPermission) {
            return flash({
                message:
          'You do not have the required permission to perform this action',
                status: 'warning',
            });
        }

        const block = blocks[block_id];

        !block.asset
            ? handleCheckMultiple(block, issue_id, currentChecked)
            : handleCheckSingle(block, issue_id, currentChecked);
    };

    const handleClickSave = (data) => {
        dispatch(updateBlocksIssues(entity_id, data, () =>
            flash({ message: 'Update success!', status: 'success' })
        ));
    };

    const buttonsDisabled = !userCanEdit || !matrixFormData.length;

    return (
        <Layout className="d-flex w-100 h-100">
            <div
                className="issue-management__v-table d-flex flex-column mr-3"
                style={{ flexGrow: 1 }}
            >
                <div className="d-flex w-100 mb-3 justify-content-end">
                    <AukTooltip.Help title="Undo all changes">
                        <Button
                            disabled={buttonsDisabled}
                            className="auk-button auk-button--round"
                            onClick={() => setConfirmation(true)}
                        >
                            <i className="fas fa-undo" />
                        </Button>
                    </AukTooltip.Help>
                    <AukTooltip.Help title="Save">
                        <Button
                            disabled={buttonsDisabled}
                            className="auk-button auk-button--round ml-2"
                            onClick={() => handleClickSave(matrixFormData)}
                        >
                            <i className="fas fa-save" />
                        </Button>
                    </AukTooltip.Help>
                </div>
                <div style={{ position: 'relative', flexGrow: 1 }}>
                    <CheckboxLegend
                        style={{
                            position: 'absolute',
                            width: 160,
                            fontSize: 10,
                        }}
                    />
                    <BlockIssueSpreadsheet
                        blocks={props.blocks}
                        issues={props.issues}
                        selectedBlocks={filteredBlock || rootBlock}
                        selectedIssues={filteredIssue || rootIssues}
                        blocks_issues_exclusions={exclusions}
                        handleClickCheckbox={handleClickCheckbox}
                    />
                </div>
            </div>
            <Sider
                className="d-flex w-100 h-100"
                style={{ flexDirection: 'column-reverse' }}
                width={280}
                collapsible
                reverseArrow
                collapsedWidth={40}
                theme="light"
                collapsed={filterCollapsed}
                onCollapse={() => setFilterCollapsed(!filterCollapsed)}
                trigger={
                    <AukTooltip.Help
                        placement="top"
                        title={filterCollapsed ? 'Expand' : 'Collapse'}
                    >
                        <FilterOutlined />
                    </AukTooltip.Help>
                }
            >
                <div className="child px-3 pt-3 pb-2" style={{ height: '50%' }}>
                    <SearchableTree
                        search={{
                            addonBefore: <div style={{ width: 70 }}>Assets</div>,
                        }}
                        tree={{
                            treeData: blocksTreeData,
                            onSelect: ([b_id]) => setFilteredBlock(blocks[b_id]),
                        }}
                        data={blocks}
                        parentKeyAccessor={(b) => b.parent_block_id}
                        nodeKeyAccessor={(treeNode) => +treeNode.key}
                        defaultExpanded={keys(blocks).map((k) => +k)}
                    />
                </div>
                <div className="child px-3 pb-3 pt-2" style={{ height: '50%' }}>
                    <SearchableTree
                        search={{
                            addonBefore: <div style={{ width: 70 }}>Issues</div>,
                        }}
                        tree={{
                            treeData: issuesTreeData,
                            onSelect: ([i_id]) => setFilteredIssue(issues[i_id]),
                        }}
                        data={issues}
                        parentKeyAccessor={(i) => i.parent_issue_id}
                        nodeKeyAccessor={(treeNode) => +treeNode.key}
                        defaultExpanded={keys(issues).map((k) => +k)}
                    />
                </div>
            </Sider>
            <ConfirmationModal
                showConfirmation={confirmation}
                onConfirmation={() => {
                    setExclusions(initialExclusionsState);
                    setConfirmation(false);
                }}
                toggleConfirmation={() => setConfirmation(false)}
                action="Undo changes"
                message="Undo all unsaved changes?"
            />
        </Layout>
    );
};

export default IssuePermissionMatrix;
