import "./ExportOverlay.scss"
import React from "react"
import AukButton from "../../legacy/components/AukButton"
import { AnyFunction } from "../../types/any"
import classNames from "classnames"
import { DownloadOutlined } from "@ant-design/icons"

const ExportOverlay = (props: Props) => {
    return <div className={classNames(["export-overlay", props.className])} style={props.style}>
        <div className="export-overlay-sticky">
            {/* {
                props.items.toSvg ? <AukButton.Outlined
                    compact
                    size="small"
                    onClick={props.items.toSvg}
                    title="Save as SVG"
                >
                    SVG
                </AukButton.Outlined> : null
            } */}
            {
                props.items.toPng ? <AukButton.Outlined
                    size="small"
                    onClick={props.items.toPng}
                    title="Save as PNG"
                >
                    <DownloadOutlined />
                </AukButton.Outlined> : null
            }
            {/* <DownloadOutlined style={{ marginRight: "0.5em" }}/> */}
        </div>
        <div className="export-overlay-content">
            {props.children}
        </div>
    </div>
}

interface Props {
    children: React.ReactNode
    items: Items
    className?: string
    style?: React.CSSProperties 
}

interface Items {
    toSvg?: AnyFunction;
    toPng?: AnyFunction;
}

export default ExportOverlay