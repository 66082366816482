import { ParetoPageConstants as K } from './pareto.constants';

export const fetchParetoPageAssetsData = (entity_id, block_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_PARETO_ASSETS_DATA,
        payload: { entity_id, block_id, query },
        callback
    }
}

export const setParetoPageAssetsData = (data) => {
    return {
        type: K.ACTIONS.SET_PARETO_ASSETS_DATA,
        payload: { data }
    }
}

export const setParetoPageViewMode = (viewMode) => {
    return {
        type: K.ACTIONS.SET_PARETO_VIEW_MODE,
        payload: { viewMode },
    }
}