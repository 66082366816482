import { put, call, all, take, takeLatest, takeEvery } from 'redux-saga/effects';

// API
import { api_getDashboardMetainfo } from './Shared.services';

// MODELS
import {
    Asset,
    parseAssetArguments,
    Metadatum,
    parseMetadatumArguments,
    Device,
    parseDeviceArguments,
    Tile,
    parseTileArguments,
    SummaryTile,
    parseSummaryTileArguments,
    Fusion,
    parseFusionArguments,
    Block,
    parseBlockArguments,
    Canvas,
    parseCanvasArguments,
    tileToWidget,
    Gateway,
    parseGatewayArguments,
    Chart,
    parseChartArguments,
} from '../../../legacy/models';

// ACTIONS
import { setAssetsChartsResource, setAssetsState } from '../Assets/Assets.action';
import { SetDevicesResource } from '../Devices/Devices.action';
import { setMetadataResource } from '../Metadata/Metadata.action';
import { setFusionsResource } from '../Fusions/Fusions.action';
import { SetDashboardTilesState } from '../Tiles/Tiles.action';
import { setBlocksState } from '../Blocks/Blocks.action';
import { setOEEState } from '../UI/OEE/OEE.action';
import { appInit } from '../rootReducer';
// import { InitControlPanel } from '../UI/Controls/Controls.action';
import { setCanvasResource } from '../Canvas/Canvas.action';
import { SetWidgetsResource } from '../Widgets/Widget.action';

// UTILS
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { SharedConstants as K } from './Shared.constants';
import { errorFlash } from '../../../legacy/components/Flash';
import { getAllUsersRequest } from '../User/User.action';
import { SetGatewaysResource } from '../Gateway/Gateway.action';
import { getAllGroupsRequest } from '../Group/Group.action';
import { fetchCoreModels } from './Shared.action';
import { METHOD_OVERRIDE, REST_METHODS, http } from '../../../legacy/utils/http';
import { session } from '../../../legacy/auxStore';
import { startLoading, stopLoading } from '../UI/Loaders/Loader.action';
import { store } from '../..';
import HttpRequestMonitor from '../../../legacy/auxStore/HttpRequestMonitor';
import { groupBy } from 'lodash';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { ReportConstants } from '../UI/Report/Report.constants';
import { setOeeReportColumns, setSkuReportColumns } from '../UI/Report/Report.action';
import moment from 'moment';
import { getBrowserTz, getRangeFromSpan } from '../../../legacy/utils/controls';
import { setAssetEventsFilter } from '../UI/asset/asset.action';

function* handleFetchCoreModels(action) {
    try {
        const { entityId } = action.payload;
        const appState = store.getState();

        const { ui: { report }, preference } = appState;
        const { tzStartDifference } = currentEntitySelector(appState);

        // set report columns
        const oee2 = preference.entity.oee ? preference.entity.oee.oee2 : true;
        const oeeReportColumns = [oee2 ? ReportConstants.REPORT_FIELDS.OEE2.key :  ReportConstants.REPORT_FIELDS.OEE1.key].concat(...report.oeeColumns);
        const skuReportColumns = [oee2 ? ReportConstants.REPORT_FIELDS.OEE2.key :  ReportConstants.REPORT_FIELDS.OEE1.key].concat(...report.skuColumns);

        // set asset events default date
        const [eventsRangeStart] = getRangeFromSpan(
            new moment(),
            { size: 12, unit: 'hours' },
            getBrowserTz(),
            tzStartDifference
        );

        let result = yield call(api_getDashboardMetainfo, entityId);
        let { assets, blocks, devices, fusions, summary_tiles, tiles, oee_masks } = result;

        let metadata = devices
            .map((d) => d.metadata)
            .reduce((acc, curr) => acc.concat(...curr), []);

        const charts = groupBy(
            assets
                .map(asset => asset.charts.map(c => (new Chart(...parseChartArguments({...c, asset_id: asset.asset_id})))))
                .reduce((acc, curr) => acc.concat(...curr), []),
            'asset_id'
        );

        const gateways = getMapFromArr(
            devices
                .filter((d) => d.model_id === 1)
                .map((g) => new Gateway(...parseGatewayArguments(g))),
            'device_id'
        );

        const WidgetTiles = tiles.map(tileToWidget);
        const WidgetSummaryTiles = summary_tiles.map(tileToWidget);
        const widgets = getMapFromArr(
            WidgetTiles.concat(WidgetSummaryTiles),
            'widget_id'
        );

        tiles = getMapFromArr(
            tiles.map((t) => new Tile(...parseTileArguments(t))),
            'tile_id'
        );

        summary_tiles = getMapFromArr(
            summary_tiles.map(
                (t) => new SummaryTile(...parseSummaryTileArguments(t))
            ),
            'summary_tile_id'
        );

        assets = getMapFromArr(
            assets
                .filter((a) => a.block)
                .map((a) => new Asset(...parseAssetArguments(a))),
            'asset_id'
        );

        devices = getMapFromArr(
            devices
                .filter((d) => d.model_id !== 1)
                .map((d) => new Device(...parseDeviceArguments(d))),
            'device_id'
        );

        metadata = getMapFromArr(
            metadata.map((m) => new Metadatum(...parseMetadatumArguments(m))),
            'metadata_id'
        );

        fusions = getMapFromArr(
            fusions.map((f) => new Fusion(...parseFusionArguments(f))),
            'fusion_id'
        );

        blocks = getMapFromArr(
            blocks.map((b) => new Block(...parseBlockArguments(b))),
            'block_id'
        );

        const masks = getMapFromArr(oee_masks, 'oee');

        const canvasses = getMapFromArr(dummyCanvases(3), 'canvas_id');

        yield all([
            put(setAssetsState({ assets })),
            put(SetDevicesResource(devices)),
            put(setMetadataResource(metadata)),
            put(setFusionsResource(fusions)),
            put(setBlocksState({ blocks })),
            put(SetDashboardTilesState(tiles, summary_tiles)),
            put(setOEEState(masks)),
            put(setCanvasResource(canvasses)),
            put(SetWidgetsResource(widgets)),
            put(getAllUsersRequest({ branch: true, invite: false })),
            put(getAllGroupsRequest()),
            put(SetGatewaysResource(gateways)),
            put(setAssetsChartsResource(charts)),
            put(setOeeReportColumns(oeeReportColumns)),
            put(setSkuReportColumns(skuReportColumns)),
            put(setAssetEventsFilter({start: eventsRangeStart, span: '12 hours'}))
        ]);

        yield put({ type: K.ACTIONS.FETCH_CORE_MODELS_SUCCESS });
    } catch (e) {
        errorFlash(e);
    }
}

export function* fetchCoreModelsSaga() {
    yield takeLatest(K.ACTIONS.FETCH_CORE_MODELS, handleFetchCoreModels);
}

function* handleAppInitFlow(action) {
    try {
        const { entityId } = action.payload;

        yield put(fetchCoreModels(entityId));
        yield take(K.ACTIONS.FETCH_CORE_MODELS_SUCCESS);

        yield put(appInit());
    } catch (e) {
        errorFlash(e);
    }
}

export function* appInitFlowSaga() {
    yield takeLatest(K.ACTIONS.APP_INIT_FLOW, handleAppInitFlow);
}

function* handleHttpRequest(action) {
    try {
        const { path, method, params, contentType, monitor, success, error } =
      action.payload;

        yield put(startLoading());

        const options = {
            method: method,
            body: params,
            headers: { 'Content-Type': contentType, 'X-Frontend-Url': window.location.href },
            mode: 'cors',
            timeout: 5,
        };

        if (monitor) {
            const controller = new AbortController();
            const { signal } = controller;
            options.signal = signal;
            HttpRequestMonitor.addRequest(path, controller);
        }

        if (method === REST_METHODS.GET) {
            delete options.body;
            delete options.headers['Content-Type'];
        }

        if (METHOD_OVERRIDE.has(method)) {
            options.headers['X-HTTP-Method-Override'] = method;
            options.method = REST_METHODS.POST;
        }

        const token = session.token;
        if (token) options.headers['Authorization'] = `Bearer ${token}`;

        http({
            path,
            options,
            success: (data) => {
                monitor && HttpRequestMonitor.removeRequest(path);
                store.dispatch(stopLoading());
                success(data);
            },
            error: (e) => {
                monitor && HttpRequestMonitor.removeRequest(path);
                store.dispatch(stopLoading());

                if (e.status === 401) {
                    session.logout()
                    window.location.href = "/login"
                    return;
                }

                error(e);
            },
        });
    } catch (e) {
        errorFlash(e);
    }
}

export function* makeRequestSaga() {
    yield takeEvery(K.ACTIONS.HTTP_REQUEST, handleHttpRequest);
}

// for canvas development
const dummyCanvases = (n) => {
    const result = [];
    for (let i = 0; i < n; i++) {
        const c = {
            canvas_id: i,
            type: 'dashboard',
            order: i,
            label: `Canvas ${i}`,
        };

        result.push(new Canvas(...parseCanvasArguments(c)));
    }

    return result;
};
