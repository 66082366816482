/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import * as d3 from 'd3';
import { OEELegend, OEEWaterfallChart } from '../../Charts';

import './ParetoWaterfall.scss';
import { savePng } from '../../utils/dataExports';
import ExportOverlay from '../../../components/ExportOverlay/ExportOverlay';
import useTranslate from '../../../hooks/useTranslate';
import CONSTANTS from '../../Constants';

const xAccessor = (d) => d.duration;
const yAccessor = (d) => d.name;
const fixBarToLeftAccessor = (d) => d.type === 'time';
const groupAccessor = (d) => d.loss;

const ParetoWaterfall = (props) => {
    const { data, accessors } = props;
    const { translate } = useTranslate()
    const yAxisTickFormatAccessor = (d) => translate(CONSTANTS.OEE.LOSS[d]);

    const waterfallProps = useMemo(
        () => ({
            margin: { top: 35, left: 60, right: 20, bottom: 20 },
            xScale: d3.scaleLinear().domain([0, d3.max(data, xAccessor)]),
            yScale: d3.scaleOrdinal().domain(data.map(yAccessor)),
            xAccessor,
            yAccessor,
            accessors: {
                x: xAccessor,
                y: yAccessor,
                fixBarToLeft: fixBarToLeftAccessor,
                group: groupAccessor,
                yAxisTickFormat: yAxisTickFormatAccessor,
                active: accessors.active,
                opacity: accessors.opacity,
                disabled: accessors.disabled,
                dataLabelFormat: accessors.formatDataLabel
            }
        }),
        [data, accessors]
    );

    return (
        <ExportOverlay 
            items={{
                // toSvg: () => saveSvg('oeeWaterfall', 'oeeWaterfall.svg'),
                toPng: () => savePng('oeeWaterfall', 'oeeWaterfall.png', { pixelRatio: 2 }),
            }}
            style={{width: '50%', overflow: 'auto'}}
        >
            <div className="pareto-waterfall">
                <OEEWaterfallChart id={'oeeWaterfall'} {...props} {...waterfallProps} />
                <OEELegend />
            </div>
        </ExportOverlay>
    );
};

export default ParetoWaterfall;
