import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"
import BlockModel from "../../../api/models/block.model"
import { flatten, uniqBy } from "lodash"
import { errorFlash } from "../../../legacy/components/Flash"
import { AnyFunction } from "../../../types/any"
import { useSelector } from "react-redux"
import { currentEntitySelector } from "../../../store/old/Entity/Entity.selector"
import { Tree } from "antd"
import { findNodes } from "../../../legacy/utils/helpers"
import useBlocks from "../../../hooks/useBlocks"

const AssetsChecklist = forwardRef((props: Props, ref) => {
    const { blocks } = useBlocks()
    const { entity_id } = useSelector(currentEntitySelector)
    const [labelled, setLabelled] = useState<Set<number>>(new Set())
    const [loading, setLoading] = useState(false)
    const [checkedKeys, setCheckedKeys] = useState<any[]>([])

    const assets = useMemo(() => findNodes(props.block, (b: BlockModel) => b.assetId), [props.block])

    useEffect(() => {
        const labelledSet: Set<number> = new Set()
        if (!props.block.children) {
            setLabelled(labelledSet)
            return
        };

        setLoading(true)
        const promises = assets
            .map(({ assetId }) => props.fetchLabels(entity_id as number, assetId as number, props.range))
        
        Promise.all(promises)
            .then((response: any) => {
                const combined = flatten(response)
                const labelledAssets = uniqBy(combined, "asset_id").map((l: any) => l.asset_id)
                setLabelled(new Set(labelledAssets))
            })
            .catch(errorFlash)
            .finally(() => setLoading(false))

    }, [assets, props.range])

    const treeData: any = useMemo(() => {
        return getTreeData(props.block, labelled)
    }, [labelled, props.block])

    useEffect(() => {
        setCheckedKeys(assets.filter(a => !labelled.has(a.assetId)).map(a => a.blockId))
    }, [assets, labelled])

    useImperativeHandle(ref, () => ({ isLoading: loading }), [loading])

    useEffect(() => {
        const checkedAssetIds = checkedKeys.filter(key => blocks[key].assetId).map(key => blocks[key].assetId)
        props.onChange(checkedAssetIds)
    }, [checkedKeys])

    return (
        <Tree
            checkable
            defaultExpandAll
            checkedKeys={checkedKeys}
            onCheck={(id) => { setCheckedKeys(id as any[]) }}
            treeData={[treeData]}
        />
    )
})

AssetsChecklist.displayName = "AssetsChecklist"

export default AssetsChecklist

interface Props {
    block: BlockModel
    range: Date[]
    fetchLabels: AnyFunction
    onChange: AnyFunction
}

const getTreeData = (block: BlockModel, disableSet: Set<any>) => {
    if (block.children && block.children.length) {
        const children : any = block.children.map((c: BlockModel) => getTreeData(c, disableSet))

        return { title: block.label, key: block.blockId, children }
    }

    return { title: block.label, key: block.blockId, disabled: disableSet.has(block.assetId)  }
}


