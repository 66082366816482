export const ParetoPageConstants = {
    ACTIONS: {
        SET_PARETO_VIEW_MODE: 'SET_PARETO_VIEW_MODE',
        FETCH_PARETO_ASSETS_DATA: 'FETCH_PARETO_ASSETS_DATA',
        SET_PARETO_ASSETS_DATA: 'SET_PARETO_ASSETS_DATA',
        RESET_PARETO_ASSETS_DATA: 'RESET_PARETO_ASSETS_DATA' 
    },
    VIEW_MODES: {
        ASSET: 'ASSET',
        ASSETS: 'ASSETS',
        BLOCK: 'BLOCK'
    }
}