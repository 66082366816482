import moment from 'moment';
import { csvDatetimeFormat } from '../../utils/helpers';
import { oeeCsv } from '../../utils/oee';
import { ceil, round } from 'lodash';
import { store } from '../../../store';

export const paretoWeightedCsv = (oeeWaterfallData, oeeParetoData, maintenanceData, effective_labels) => {
    let concurrentProductionLabels = [];

    const lossesSection = oeeCsv(oeeWaterfallData);

    const paretoHeaders = 'Issue, Total Effective Duration (hrs), Occurrence\n';
    const paretoSection =
    paretoHeaders +
    oeeParetoData
        .map((d) => {
            const issueName = d.issue && d.issue.name ? d.issue.name : 'DELETED';
            const totalEfDuration = (d.total_effective_duration / 3600).toFixed(2);
            return `${issueName}, ${totalEfDuration}, ${d.occurrence}`;
        })
        .join('\n');

    const labels = effective_labels
        .map((d) => {
            const { from, to, issue, output } = d;
            return [
                issue ? issue : 'DELETED',
                moment(from).format(csvDatetimeFormat),
                d.isComplete ? moment(to).format(csvDatetimeFormat) : '-',
                d.isComplete ? d.getDuration('hours').toFixed(2) : '-',
                output,
                concurrentProductionLabels.join(','),
            ];
        })
        .join('\n');

    const labelSection =
    '(OEE) Cause,Start,End,Duration (hrs),Output,' + '\n' + labels;

    let maintenanceSection = '';

    if (maintenanceData) {
        const mtbfRow = `MTBF (hrs),${secondsToHours(maintenanceData.mtbf)}\nMTBF (min),${secondsToMinutes(maintenanceData.mtbf)}\n`
        const mttrRow = `MTTR (hrs),${secondsToHours(maintenanceData.mttr)}\nMTTR (min),${secondsToMinutes(maintenanceData.mttr)}\n`
        maintenanceSection = mtbfRow + mttrRow;
    }

    return [lossesSection, paretoSection, labelSection, maintenanceSection].join('\n\n');
};

export const paretoSumCsv = (oeeWaterfallData, oeeParetoData, effective_labels) => {
    const lossesHeader = 'OEE Category, Duration (hrs)';
    const lossesBody = oeeWaterfallData
        .map((d, i) => formatLossesRow(d, i))
        .join('\n');
    const lossesSection = [lossesHeader, lossesBody].join('\n');

    const paretoHeaders = 'Issue, Total Effective Duration (hrs), Occurrence\n';
    const paretoSection =
    paretoHeaders +
    oeeParetoData
        .map((d) => {
            const issueName = d.issue && d.issue.name ? d.issue.name : 'DELETED';
            const totalEfDuration = (d.total_effective_duration / 3600).toFixed(2);
            return `${issueName}, ${totalEfDuration}, ${d.occurrence}`;
        })
        .join('\n');

    const labels = effective_labels
        .map((d) => {
            const { from, to, issue, output } = d;
            return [
                issue ? issue : 'DELETED',
                moment(from).format(csvDatetimeFormat),
                d.isComplete ? moment(to).format(csvDatetimeFormat) : '-',
                d.isComplete ? d.getDuration('hours').toFixed(2) : '-',
                output,
            ];
        })
        .join('\n');

    const labelSection =
    '(OEE) Cause,Start,End,Duration (hrs),Output,' + '\n' + labels;

    return [lossesSection, paretoSection, labelSection].join('\n\n');
};

const formatLossesRow = (d, index) => {
    const { masks } = store.getState().ui.oee;
    const { type, key, name, duration } = d;
    const abbr = (masks[key] ? masks[key].abbreviation : key).toUpperCase();
    const category = masks[key] ? masks[key].mask : name;
    const durationHrs = round(duration / 3600, 2);

    const str = `[${abbr}] ${category}, ${durationHrs}`;
    return type !== 'loss' && index ? `\n${str}` : str;
}

const secondsToMinutes = seconds => seconds < 0 ? '--' : ceil(seconds / 60, 1);
const secondsToHours = seconds => seconds < 0 ? '--' : ceil(seconds / 3600, 1)

