import { pickBy } from 'lodash';
import { updateStoreRedux } from '../../../../legacy/utils/redux';
import { ParetoPageConstants as K } from './pareto.constants';

const getInitialState = () => {
    return {
        viewMode: K.VIEW_MODES.BLOCK, // use for block pareto
        data: {
            assets: {}
        },
    };
};

export const ParetoPageReducer = (
    state = { ...getInitialState() },
    action
) => {
    if (action.type === K.ACTIONS.SET_PARETO_VIEW_MODE) {
        return updateStoreRedux(state, {
            viewMode: action.payload.viewMode
        });
    }

    if (action.type === K.ACTIONS.SET_PARETO_ASSETS_DATA) {
        return updateStoreRedux(state, {
            data: {
                ...state.data,
                assets: {
                    ...action.payload.data
                }
            }
        });
    }

    if (action.type === K.ACTIONS.RESET_PARETO_ASSETS_DATA) {
        return updateStoreRedux(state, {
            data: {
                assets: {}
            }
        });
    }

    return updateStoreRedux(state, {});
};
