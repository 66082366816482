/* eslint-disable react/prop-types */
import React from 'react';

import VSpreadsheet from '../../../components/VSpreadsheet';
import { Checkbox, CheckboxState } from '../../../components/Checkbox';

import { depthFirstTraversal, findNodes } from '../../../utils/helpers';

export default class BlockIssueSpreadsheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            axisCollapsed: new Set(),
            headerCollapsed: new Set(),
            axisNodes: [],
            headerNodes: [],
        };

        this.handleExpand = this.handleExpand.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this);
        this.renderAxisCell = this.renderAxisCell.bind(this);
        this.renderBodyCell = this.renderBodyCell.bind(this);
        this.renderHeaderCell = this.renderHeaderCell.bind(this);
    }

    componentDidMount() {
        this.setState({
            axisNodes: this.getAxisNodes(),
            headerNodes: this.getHeaderNodes(),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.selectedBlocks !== this.props.selectedBlocks ||
      prevProps.selectedIssues !== this.props.selectedIssues
        ) {
            this.setState(
                { axisCollapsed: new Set(), headerCollapsed: new Set() },
                () =>
                    this.setState({
                        axisNodes: this.getAxisNodes(),
                        headerNodes: this.getHeaderNodes(),
                    })
            );
            return;
        }

        if (prevState.axisCollapsed !== this.state.axisCollapsed) {
            this.setState({ axisNodes: this.getAxisNodes() });
            return;
        }

        if (prevState.headerCollapsed !== this.state.headerCollapsed) {
            this.setState({ headerNodes: this.getHeaderNodes() });
            return;
        }
    }

    handleCollapse(field, e) {
        const result = new Set(this.state[field]).add(e);
        this.setState({ [field]: result });
    }

    handleExpand(field, e) {
        const result = new Set(this.state[field]);
        result.delete(e);

        this.setState({ [field]: result });
    }

    getAxisNodes() {
    // flattened array of blocks <TreeNode>s
    // sorted depth first
        const { axisCollapsed } = this.state;

        const recurse = (n) => {
            const { block_id, children, label, hierarchy_level, asset_id } = n;

            const icon = !asset_id ? (
                <span
                    className="d-flex mr-1 align-items-center"
                    key={!asset_id}
                    style={{ fontSize: 10 }}
                >
                    <i className="fas fa-th-large" />
                </span>
            ) : (
                <span
                    className="d-flex mr-1 align-items-center"
                    key={!asset_id}
                    style={{ fontSize: 10 }}
                >
                    <i className="fas fa-square" />
                </span>
            );

            const result = {
                block_id,
                label,
                hierarchy_level,
                isLeaf: asset_id || !children.length,
                icon,
            };

            if (!children.length || axisCollapsed.has(block_id)) return result;

            result.children = children.map((c) => recurse(c));
            return result;
        };

        const expandedNodes = recurse(this.props.selectedBlocks);

        const nodes = [];
        depthFirstTraversal(expandedNodes, (n) => nodes.push(n));
        return nodes;
    }

    getHeaderNodes() {
    // flattened array of issues <TreeNode>s
    // sorted depth first
        const { headerCollapsed } = this.state;

        const recurse = (n) => {
            const { issue_id, name, hierarchy_level, children, color } = n;

            let icon;
            hierarchy_level === 2 &&
        (icon = (
            <span className="d-flex mb-1" style={{ fontSize: 14, color }}>
                <i className="fas fa-square" />
            </span>
        ));

            const result = {
                issue_id,
                name,
                hierarchy_level,
                isLeaf: !children.length,
                icon,
            };

            if (!children.length || headerCollapsed.has(issue_id)) return result;

            result.children = children.map((c) => recurse(c));
            return result;
        };

        const issues = Array.isArray(this.props.selectedIssues)
            ? this.props.selectedIssues
            : [this.props.selectedIssues];

        return issues
            .map((i) => {
                const expandedNodes = recurse(i);
                const nodes = [];
                depthFirstTraversal(expandedNodes, (n) => nodes.push(n));
                return nodes;
            })
            .reduce((acc, curr) => acc.concat(curr), []);
    }

    get topHierarchyAxis() {
        const { axisNodes } = this.state;
        return axisNodes[0].hierarchy_level;
    }

    get topHierarchyHeader() {
        const { headerNodes } = this.state;
        return headerNodes[0].hierarchy_level;
    }

    evaluateCheckForNonAssetBlock(b_id, i_id) {
        const { blocks } = this.props;

        const block = blocks[b_id];
        const leaves = findNodes(block, (b) => b.asset_id);

        const checkedCount = leaves.reduce((acc, curr) => {
            if (
                this.evaluateCheckForAssetBlock(curr.block_id, i_id) ===
        CheckboxState.CHECKED
            )
                return acc + 1;
            return acc;
        }, 0);

        const indeterminate = checkedCount < leaves.length && checkedCount > 0;
        const allChecked = checkedCount === leaves.length;

        return allChecked
            ? CheckboxState.CHECKED
            : indeterminate
                ? CheckboxState.INDETERMINATE
                : CheckboxState.UNCHECKED;
    }

    evaluateCheckForAssetBlock(b_id, i_id) {
        const { blocks_issues_exclusions } = this.props;

        return !(
            blocks_issues_exclusions[b_id] &&
      blocks_issues_exclusions[b_id][i_id] &&
      !blocks_issues_exclusions[b_id][i_id].checked
        )
            ? CheckboxState.CHECKED
            : CheckboxState.UNCHECKED;
    }

    tableData(columns, rows) {
        const { blocks, issues } = this.props;

        return rows.map((r) => {
            const { block_id } = r;
            const block = blocks[block_id];
            return columns.map((c) => {
                const { issue_id } = c;

                const isEmptyBlock = !block.asset_id && !block.children.length;
                if (isEmptyBlock)
                    return {
                        disabled: true,
                        indeterminate: false,
                        checked: false,
                    };

                const value = block.asset_id
                    ? this.evaluateCheckForAssetBlock(block_id, issue_id)
                    : this.evaluateCheckForNonAssetBlock(block_id, issue_id);

                const disabledIssue = issues[issue_id]
                    ? issues[issue_id].hierarchy_level === 1
                    : true;
                const indeterminate = value === CheckboxState.INDETERMINATE;
                const checked = disabledIssue ? false : value === CheckboxState.CHECKED;

                const disabled = disabledIssue || block.isRestricted;

                return { disabled, checked, indeterminate };
            });
        });
    }

    renderAxisCell({ columnIndex, key, rowIndex, style, value }) {
        const { hierarchy_level, block_id, isLeaf, icon } = value;
        const displace = hierarchy_level - this.topHierarchyAxis;

        const customStyle = {
            marginLeft: `${displace * 15}px`,
        };

        const { label } = value;
        return (
            <TreeNode
                style={customStyle}
                data={value}
                isLeaf={isLeaf}
                handleExpand={() => this.handleExpand('axisCollapsed', block_id)}
                handleCollapse={() => this.handleCollapse('axisCollapsed', block_id)}
                isExpanded={!this.state.axisCollapsed.has(block_id)}
            >
                <div className="d-flex">
                    {icon}
                    {label}
                </div>
            </TreeNode>
        );
    }

    renderBodyCell({ columnIndex, key, rowIndex, style, value }) {
        const { headerNodes, axisNodes } = this.state;
        const { block_id } = axisNodes[rowIndex];
        const { issue_id } = headerNodes[columnIndex];

        const { checked, indeterminate, disabled } = value;

        return (
            <div
                className={`d-flex checkbox-container ${
                    disabled ? '' : 'highlightable'
                }`}
            >
                <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={() =>
                        this.props.handleClickCheckbox(
                            block_id,
                            issue_id,
                            checked,
                            !disabled
                        )
                    }
                    indeterminate={indeterminate}
                />
            </div>
        );
    }

    renderHeaderCell({ columnIndex, key, rowIndex, style, value }) {
        const { hierarchy_level, name, issue_id, isLeaf, icon } = value;
        const displace = hierarchy_level - this.topHierarchyHeader;

        const customStyle = {
            writingMode: 'vertical-lr',
            transform: 'scaleX(-1)',
            marginTop: displace * 15,
        };

        return (
            <TreeNode
                style={customStyle}
                data={value}
                isLeaf={isLeaf}
                handleExpand={() => this.handleExpand('headerCollapsed', issue_id)}
                handleCollapse={() => this.handleCollapse('headerCollapsed', issue_id)}
                isExpanded={!this.state.headerCollapsed.has(issue_id)}
                isVertical
            >
                <div className="d-flex align-items-center">
                    {icon}
                    {name}
                </div>
            </TreeNode>
        );
    }

    render() {
        const { headerNodes, axisNodes } = this.state;

        return (
            <VSpreadsheet
                cellWidth={30}
                cellHeight={30}
                overflowHeaderHeight={250}
                headerHeight={120}
                axisWidth={180}
                data={this.tableData(headerNodes, axisNodes)}
                columns={headerNodes}
                rows={axisNodes}
                columnAccessor={(i) => i}
                rowAccessor={(b) => b}
                renderBodyCell={this.renderBodyCell}
                renderHeaderCell={this.renderHeaderCell}
                renderLeftSideCell={this.renderAxisCell}
                rowKeyAccessor={(b) => b.block_id}
                columnKeyAccessor={(i) => i.issue_id}
                showMarkingLines={false}
                highlightOnHover={false}
            />
        );
    }
}

const TreeNode = ({
    style,
    isLeaf,
    handleExpand,
    handleCollapse,
    isExpanded,
    label,
    children,
    isVertical,
}) => {
    style = {
        whiteSpace: 'nowrap',
        fontSize: 12,
        ...style,
    };

    const handleClick = isExpanded ? handleCollapse : handleExpand;

    const iconStyle = {
        width: 20,
        height: 20,
        fontSize: 11,
        transform: isVertical ? 'rotate(90deg)' : '',
    };

    const labelStyle = isVertical
        ? { marginTop: isLeaf ? 20 : 0 }
        : { marginLeft: isLeaf ? 20 : 0 };

    const icon = isLeaf ? null : (
        <div
            className="d-flex justify-content-center align-items-center"
            style={iconStyle}
            key={isExpanded}
            onClick={isExpanded ? handleCollapse : handleExpand}
        >
            {isExpanded ? (
                <i className="far fa-minus-square" />
            ) : (
                <i className="far fa-plus-square" />
            )}
        </div>
    );

    return (
        <div
            className="tree-node d-flex justify-content-start align-items-center"
            style={style}
        >
            {icon}
            <div style={labelStyle}>{label || children}</div>
        </div>
    );
};
