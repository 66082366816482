/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BlocksTreeSelect from '../../../components/BlocksTreeSelect';
import CONSTANTS from '../../../Constants';
import withAsset from '../../../Wrappers/HOCs/withAsset';
import { SPAWrapper } from '../../../components/SPAWrapper';
import { withEntity } from '../../../Wrappers/HOCs/withEntity';
import { defaultFilterOption, uiDatetimeFormatWithSeconds } from '../../../utils/helpers';
import { getBlocksTreeData } from '../../../utils/blocks';

import './index.scss';
import AssetEventsFilter from './AssetEventsFilter';
import { createLabelFromEvent, deleteLabelFromEvent, fetchAssetEventsPageData, setAssetEventsFilter, updateLabelFromEvent } from '../../../../store/old/UI/asset/asset.action';
import { labelDisplayText } from '../../../utils/labels';
import { LabelsChartTooltip } from '../../../Charts/v1/tooltips/templates';
import * as d3 from 'd3';
import { LabelsChart } from '../../../Charts/v1';
import OEEStackColumnChart from '../../../Charts/OEEStackColumnChart';
import { dataApiQueryRange } from '../../../utils/controls';
import VTable from '../../../components/VTable';
import IssueTreeSelect from './IssueTreeSelect';
import { round } from 'lodash';
import { withIssues } from '../../../Wrappers/HOCs/withIssues';
import { OeeToIssue } from '../../../models';
import { LabelEvent } from '../../../../store/old/UI/asset/asset.utils';
import InputWithSubmit from '../../../components/AukInput/InputWithSubmit';
import { fetchBlockIssues } from '../../../../store/old/Blocks/Blocks.action';
import withRouter from '../../../Wrappers/HOCs/withRouter';

const AssetEventsPage = ({ asset, entity }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { block_id } = asset;
    const issuesResource = useSelector(appState => appState.issues.issues);
    const masks = useSelector(appState => appState.ui.oee.masks);
    const assetEventsOee = useSelector(appState => appState.ui.asset.events.oee);
    const assetEventsLabels = useSelector(appState => appState.ui.asset.events.labels);
    const assetEvents = useSelector(appState => appState.ui.asset.events.events);
    const assetEventsFilterState = useSelector(appState => appState.ui.asset.events.filter);
    const sku_oee = useSelector(appState => appState.ui.controls.sku_oee);

    useEffect(() => { dispatch(fetchBlockIssues(entity.entity_id, asset.block_id)) }, []);

    const fetchPageData = useCallback(() => {
        const { start, span, threshold } = assetEventsFilterState;
        const dateRange = getDateRange(start, span);
        const resolution = findMinResolution(asset);
        const [windowStart, windowEnd] = dateRange;
        
        const date_range = dataApiQueryRange({ resolution, startDate: windowStart, endDate: windowEnd });

        dispatch(fetchAssetEventsPageData(entity.entity_id, asset.asset_id, date_range, resolution, { uu: threshold, ms: threshold }, sku_oee));
    }, [assetEventsFilterState, sku_oee]);

    const triggerPageDataRefresh = useCallback(() => { dispatch(setAssetEventsFilter({})) }, []);

    useEffect(() => { fetchPageData() }, [fetchPageData]);

    const dates = useMemo(() => {
        const { start, span } = assetEventsFilterState;
        const [span_x, span_period] = span.split(' ');

        return [start, start.clone().add(span_x, span_period)];
    }, [assetEventsFilterState]);

    const xDomain = useMemo(() => dates.map(d => d.toDate()), [dates]);

    const columns = [
        {
            title: 'OEE',
            render: (text, record, index) => {
                return masks[record.oee] ? masks[record.oee].mask : OeeToIssue[record.oee]
            },
            width: 50,
        },
        {
            title: 'Start',
            render: (text, record, index) => moment(record.from).format(uiDatetimeFormatWithSeconds),
            sorter: (a, b) => {
                const unixA = moment(a.from).unix();
                const unixB = moment(b.from).unix();
                return unixA - unixB;
            },
            width: 100,
        },
        {
            title: 'End',
            render: (text, record, index) => moment(record.to).format(uiDatetimeFormatWithSeconds),
            sorter: (a, b) => {
                const unixA = moment(a.to).unix();
                const unixB = moment(b.to).unix();
                return unixA - unixB;
            },
            width: 100,
        },
        {
            title: 'Duration (min)',
            render: (text, record, index) => round(record.duration).toLocaleString(),
            sorter: (a, b) => a.duration - b.duration,
            width: 100,
        },
        {
            title: 'Label',
            render: (text, record, index) => (
                <IssueTreeSelect
                    allowClear
                    showArrow={!record.label}
                    defaultIssue={record.label?.issue} // fallback for deleted issue
                    value={record.issue_id} 
                    asset={asset} 
                    onChange={e => {
                        if (!e && record.label_id) {
                            dispatch(deleteLabelFromEvent(
                                entity.entity_id, 
                                asset.asset_id, 
                                record.label_id,
                                triggerPageDataRefresh
                            ));
                            return;
                        }

                        if (record.label_id) {
                            dispatch(updateLabelFromEvent(
                                entity.entity_id, 
                                asset.asset_id, 
                                {
                                    label_id: record.label_id,
                                    values: { issue: e, notes: record.label.notes },
                                    from: record.from,
                                    to: record.to,
                                },
                                record.label ? { defects: record.label.defects } : undefined,
                                triggerPageDataRefresh
                            )); 
                            return;
                        }
                        
                        dispatch(createLabelFromEvent(
                            entity.entity_id, 
                            asset.asset_id,
                            {
                                values: { issue: e },
                                from: record.from,
                                to: record.to,
                            },
                            triggerPageDataRefresh
                        ));
                    }}
                />
            ),
            width: 200,
        },
        {
            title: 'Notes',
            render: (text, record, index) => {
                return (
                    <div key={record.id} className="events-label-notes-input">
                        <InputWithSubmit
                            value={record.label ? record.label.notes : ''}
                            disabled={!record.label || !issuesResource[record.label.issue.issue_id]}
                            submit={(value) => {
                                dispatch(updateLabelFromEvent(
                                    entity.entity_id, 
                                    asset.asset_id, 
                                    {
                                        label_id: record.label_id,
                                        values: { issue: record.label.issue.issue_id, notes: value },
                                        from: record.from,
                                        to: record.to,
                                    },
                                    record.label ? { defects: record.label.defects } : undefined,
                                    triggerPageDataRefresh
                                ));
                            }}
                        />
                    </div>
                );
            },
            width: 150,
        },
    ];

    const issueLabels = useMemo(() => assetEventsLabels.filter(l => l.issue && l.isComplete), [assetEventsLabels]);
    const prodLabels = useMemo(() => assetEventsLabels.filter(l => !l.issue && l.isComplete), [assetEventsLabels]);
    const dataSource = useMemo(() => {
        return getEventsListData(assetEvents, issueLabels)
            .filter(e => {
                if (e.from.getTime() >= e.to.getTime()) {
                    return false;
                }

                const durationMinutes = moment.duration(moment(e.to).diff((moment(e.from)))).as('minutes');
                return durationMinutes >= assetEventsFilterState.threshold;
            });
    }, [assetEvents, assetEventsFilterState.threshold]);

    return (
        <SPAWrapper className="asset-events">
            <div className="asset-events__header">
                <div className="d-flex flex-column" style={{height: 60, marginRight: 14}}>
                    <BackAnchor/>
                    <BlocksTreeSelect
                        value={block_id}
                        onChange={(id) => { navigate(`${CONSTANTS.URLS.ASSET}/${id}/events`) }}
                        style={{ width: 240 }}
                        treeDefaultExpandAll
                        showSearch
                        filterTreeNode={defaultFilterOption}
                        getTreeData={(node) =>
                            getBlocksTreeData(node, {
                                disabledAccessor: (n) => !n.asset || n.isRestricted,
                            })
                        }
                    />
                </div>
                <div>
                    <AssetEventsFilter/>
                </div>
            </div>
            <div className="asset-events__body">
                <div style={{ height: 18, flexShrink: 0, marginBottom: '0.5em' }}>
                    <LabelsChart
                        className="asset-events-labels-chart"
                        margin={{ top: 0, left: 20, right: 20, bottom: 0 }}
                        xAccessor={(d) => new Date(d.from)}
                        x2Accessor={(d) => new Date(d._to)}
                        colorAccessorLabel={(d) => d.color}
                        colorAccessorText={() => '#fafafa'}
                        textAccessor={labelDisplayText}
                        useTooltip={true}
                        htmlTooltip={LabelsChartTooltip}
                        keyAccessor={(d) => d.label_id}
                        xScale={d3.scaleTime()}
                        xDomain={xDomain}
                        data={prodLabels}
                    />
                </div>
                <div style={{ height: 18, flexShrink: 0 }}>
                    <LabelsChart
                        className="asset-events-labels-chart"
                        margin={{ top: 0, left: 20, right: 20, bottom: 0 }}
                        xAccessor={(d) => new Date(d.from)}
                        x2Accessor={(d) => new Date(d._to)}
                        colorAccessorLabel={(d) => d.color}
                        colorAccessorText={() => '#fafafa'}
                        textAccessor={labelDisplayText}
                        useTooltip={true}
                        htmlTooltip={LabelsChartTooltip}
                        keyAccessor={(d) => d.label_id}
                        xScale={d3.scaleTime()}
                        xDomain={xDomain}
                        data={issueLabels}
                    />
                </div>
                <div style={{ height: 120, flexShrink: 0 }}>
                    <OEEStackColumnChart
                        xDomain={xDomain}
                        data={assetEventsOee.oee}
                        margin={{ top: 10, left: 20, right: 20, bottom: 20 }}
                    />
                </div>
                <div className="asset-events__body__list-wrapper">
                    <VTable
                        rowHeight={60}
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={(eventItem) => eventItem.id}
                    />
                </div>
            </div>
        </SPAWrapper>
    );
};

export default withEntity(withAsset(withIssues(AssetEventsPage)));

const BackAnchor = withRouter((props) => {
    const assetPath = useMemo(() => {
        const blockId = props.router.params.blockId;
        return `${CONSTANTS.URLS.ASSET}/${blockId}`;
    }, []);

    return (
        <div className="asset-back-anchor">
            <Link to={assetPath}>Go to asset</Link> <span className="mx-2">/</span> <Link to={`${assetPath}/operator`}>operator</Link>
        </div>
    )
})


const getDateRange = (start, span) => {
    const [span_x, span_period] = span.split(' '); 
    return [start, start.clone().add(span_x, span_period)];
}

const findMinResolution = (asset) => {
    const maxSamplingRate = asset._charts.reduce((acc, curr) => {
        if (curr.dataSource.samplingRate > acc) {
            return curr.dataSource.samplingRate;
        }
        return acc;
    }, 0);

    if (maxSamplingRate <= 60) {
        return { res_x: 1, res_period: 'minutes' };
    }

    return CONSTANTS.RESOLUTIONS.find(r => {
        return moment.duration(r.res_x, r.res_period).as('seconds') === maxSamplingRate 
    });
}

const getEventsListData = (eventsArray, labelsArray) => { 
    const events = eventsArray.map(e => ({ ... e})).sort((a, b) => a.from - b.from); // this requires mutation
    const labels = labelsArray.map(l => l).sort((a, b) => a.from - b.from);

    const result = []
    let i = 0, j = 0;

    // Step 2: Use two-pointer technique
    while (i < events.length || j < labels.length) {
        let event = events[i];
        let label = labels[j];

        if (!event) {
            result.push(LabelEvent({
                label_id: label.label_id,
                from: label.from,
                to: label.to,
                oee: label.issue.oee,
                issue_id: label.issue.issue_id,
                label,
            }));
            j++;
            continue;
        }

        if (!label) {
            result.push(LabelEvent({
                from: event.from,
                to: event.to,
                oee: event.cat,
            }));
            i++;
            continue;
        }


        // ✅ Check for exact match (same from & end)
        if (event.from.getTime() === label.from.getTime() && event.to.getTime() === label.to.getTime()) {
            result.push(LabelEvent({
                label_id: label.label_id,
                from: label.from,
                to: label.to,
                oee: label.issue.oee,
                issue_id: label.issue.issue_id,
                label,
            }));
            i++;
            j++;
            continue;
        }

        // ✅ Check for overlap
        if (overlap(event, label)) {
            if (event.from.getTime() <= label.from.getTime()) {
                // add truncated first part of event
                result.push(LabelEvent({
                    from: event.from,
                    to: label.from,
                    oee: event.cat,
                }));
            }

            if (label.to.getTime() <= event.to.getTime()) {
                // add label
                result.push(LabelEvent({
                    label_id: label.label_id,
                    from: label.from,
                    to: label.to,
                    oee: label.issue.oee,
                    issue_id: label.issue.issue_id,
                    label
                }));

                // modify event start
                event.from = label.to;
            }
        } else if (event.to.getTime() <= label.from.getTime()) {
            result.push(LabelEvent({
                from: event.from,
                to: event.to,
                oee: event.cat,
            }));
        } else if (label.to.getTime() <= event.from.getTime()) {
            result.push(LabelEvent({
                label_id: label.label_id,
                from: label.from,
                to: label.to,
                oee: label.issue.oee,
                issue_id: label.issue.issue_id,
                label
            }));
        }

        // Move the pointer of the event that ends first
        if (event.to.getTime() < label.to.getTime()) {
            if (i <  events.length) i++; // Move to the next event in A
        } else {
            if (j < labels.length) j++; // Move to the next event in B
        }
    }

    return result;
}

const overlap = (a, b) => {
    return a.from.getTime() < b.to.getTime() && b.from.getTime() < a.to.getTime();
}