import { Modal } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { blocksSelector } from '../../../store/old/Blocks/Blocks.selector';
import { values } from 'lodash';
import ParetoLabelList from './ParetoLabelList';
import { getDefaultResolution } from '../../utils/controls';
import AukButton from '../../components/AukButton';
import moment from 'moment';
import { generateUrlQuery } from '../../utils/url';
import CONSTANTS from '../../Constants';

const ParetoLabelsModal = (props) => {
    const blocks = useSelector(blocksSelector)

    const assetIdToBlock = useMemo(() => {
        return values(blocks).reduce((acc, curr) => {
            if (!curr.asset) return acc;
            return { ...acc, [curr.asset.asset_id]: curr }
        }, {})
    }, []);

    const modalContent = useMemo(() => {
        if (!(props.data && props.data.length)) return null;
        const data = props.data
            .map((d) => {
                const { asset, block_id } = assetIdToBlock[d.asset_id];
                return { label: { ...d, asset }, url: getLabelUrl(d, block_id) }
            });

        return <ParetoLabelList data={data} />;
    }, [assetIdToBlock, props.data]);

    return (
        <Modal
            className="pareto-modal"
            width={780}
            title={props.title}
            open={props.open}
            onCancel={props.cancel}
            footer={
                <div className="d-flex justify-content-end">
                    <AukButton.Blue onClick={props.cancel}>
                        Ok
                    </AukButton.Blue>
                </div>
            }
        >
            {modalContent}
        </Modal>
    )
}

export default ParetoLabelsModal;

const getLabelUrl = (label, blockId) => {
    const startDate = moment(label.from);
    const endDate = label.to ? moment(label.to) : moment();
    const duration = endDate.diff(startDate, 'seconds');
    const resolution = getDefaultResolution(duration);
    const pathname = `${CONSTANTS.URLS.ASSET}/${blockId}`;
    
    const query = generateUrlQuery({ startDate, endDate, resolution, span: 'Custom' });
    return `${pathname}${query}`
};