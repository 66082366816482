/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { AimOutlined, EyeOutlined } from '@ant-design/icons';

import AukButton from '../../components/AukButton';

import './ParetoList.scss';
import { Table, Typography } from 'antd';
import { savePng } from '../../utils/dataExports';
import ExportOverlay from '../../../components/ExportOverlay/ExportOverlay';


const ParetoList = (props) => {
    const { data, title, handleTrack, handleInspect } = props;

    const columns = useMemo(
        () => [
            {
                title: (
                    <div className="pareto-list-header pareto-list-header__tracking">
                        Tracked
                    </div>
                ),
                render: (text, record, index) => (
                    <div className="pareto-list-item__tracking">
                        <AukButton.Outlined
                            className="auk-button--round"
                            onClick={() => handleTrack(record)}
                            style={{
                                opacity: record.isTracked ? 1 : 0.4,
                                color: record.isTracked ? '#dc3545' : '#616161',
                            }}
                        >
                            <AimOutlined />
                        </AukButton.Outlined>
                    </div>
                ),
                sorter: (a, b) => Number(!!a.isTracked) - Number(!!b.isTracked),
                width: 60,
            },
            {
                title: (
                    <div className="pareto-list-header pareto-list-header__count">
                        Occurrences
                    </div>
                ),
                render: (text, record, index) => (
                    <div className="pareto-list-item__count">{record.occurrence}</div>
                ),
                sorter: (a, b) => a.occurrence - b.occurrence,
                width: 80,
            },
            {
                title: (
                    <div className="pareto-list-header pareto-list-header__bar">
                        Labels
                    </div>
                ),
                render: (text, record, index) => {
                    const width = record.width <= 0 ? '1px' : record.width + '%';

                    return (
                        <div className="pareto-list-item__bar-wrapper">
                            <div
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    position: 'absolute',
                                    height: '100%',
                                    zIndex: 1,
                                }}
                            />
                            <div className="pareto-list-item__bar" style={{ width }} />
                            <div className="pareto-list-item__bar-label">{record.label}</div>
                            <div
                                style={{
                                    background: record.color,
                                    mixBlendMode: 'screen',
                                    width,
                                    zIndex: 4,
                                    position: 'absolute',
                                    height: '100%',
                                }}
                            />
                        </div>
                    );
                },
                sorter: (a, b) => a.total_effective_duration - b.total_effective_duration,
                width: 300,
            },
            {
                title: '',
                render: (text, record, index) => (
                    <div className="pareto-list-item__button">
                        <AukButton.Outlined
                            className="auk-button--round"
                            onClick={() => handleInspect(record)}
                        >
                            <EyeOutlined />
                        </AukButton.Outlined>
                    </div>
                ),
                width: 60,
            },
        ],
        []
    );

    return (
        <ExportOverlay 
            items={{
                // toSvg: () => saveSvg('paretoList', 'paretoList.svg'),
                toPng: () => savePng('paretoList', 'paretoList.png', { 
                    pixelRatio: 2,
                })
            }} 
            style={{height: '100%'}}
        >
            <div className="pareto-list">
                <Typography.Title level={5}>{title}</Typography.Title>
                <div className="d-flex flex-column h-100">
                    <Table
                        id={"paretoList"}
                        style={{overflow: 'auto'}}
                        sticky
                        columns={columns}
                        dataSource={data}
                        rowKey={({ issue }) => `${issue.issue}-${issue.issue_id}`}
                        pagination={false}
                    />
                </div>
            </div>
        </ExportOverlay>
    );
};

export default ParetoList;
